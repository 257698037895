import fr from "../assets/flags/fr.png";
import us from "../assets/flags/us.png";

const Language = [
  {
    name: "fr",
    flag: fr,
  },
  {
    name: "en",
    flag: us,
  },
];

export default Language;
