const Nav = {
  fr: {
    link1: "Me connaitre",
    link2: "Skills",
    link3: "Projets",
    link4: "Me contacter",
  },
  en: {
    link1: "To know me",
    link2: "Skills",
    link3: "Projects",
    link4: "Contact me",
  },
};

export default Nav;